.App {
  font-family: 'Roboto Slab', serif !important;
  min-height: 100vh !important;
  width: 100vw !important;
}

.rounded {border-radius: 1rem !important;}

.header {
  height: 5rem !important;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0.75rem);
}

.header-text {font-weight: 500;}

.hero {
  height: 100vh !important;
  min-height: 100vh !important;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 20vh !important;
  background-image: url("https://i.pinimg.com/originals/4b/f6/89/4bf68956b86298e132f5157a309a7661.jpg");
  background-position: center;
}

.hero-row,
.hero-col {
  height: 100% !important;
}

.hero-left {
  max-width: 35rem;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.9rem);
}

.tagline {
  text-wrap: balance !important;
  font-weight: 800;
}

.minecraft {
  height: 100vh;
  height: 100svh;
  min-height: 100vh;
  min-height: 100svh;
  display: flex;
  flex-direction: column !important;
  background-image: url("https://static.wikia.nocookie.net/minecraft_gamepedia/images/6/62/Spruce_Planks_%28texture%29_JE4_BE2.png");
}

.mc-row {flex-grow: 1 !important;}

.status {
  padding: 0 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.myspan {width: 100% !important;}

.mc-card {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0.75rem);
  flex-wrap: wrap;
}

.gallery {
  min-height: 100vh;
  background-image: url("https://static.wikia.nocookie.net/minecraft_gamepedia/images/9/98/Netherrack_%28texture%29_JE4_BE3.png/");
}

.carousel {border: solid rgba(0, 0, 0, 0.6) thin;}

.carousel-item img {
  object-fit: cover;
  height: 40rem !important;
}

@media (min-width: 768px) {
  .tagline {font-size: 2.75rem;}
  .mc-card-info {font-size: 1.5rem;}
  .header {padding-left: 2rem;}
  .header-text {font-size: 2rem;}
}
@media (max-width: 767px) {
  .h-sm-50 {height: 50% !important;}
  .tagline {font-size: 2rem;}
  .mc-card-info {font-size: 1rem;}
  .header {
    justify-content: center !important;
    padding-left: 0;
  }
  .header-text {font-size: 1.5rem;}
}